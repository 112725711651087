export const useGuestAppStore = defineStore('guestApp', {
  state: () => {
    return {
      isLoaded: false,
      event: null,
      guest: null,
      items: null,
      responses: null,
    };
  },

  actions: {
    async fetchApp() {
      let token = null;

      if (useRoute().query?.a) {
        const tokenStorage = useLocalStorage(`tokens.${useRoute().params.event}`, '');
        tokenStorage.value = useRoute().query?.a;
        token = tokenStorage.value;

        const isNativeApp = useLocalStorage('isNativeApp', '');
        isNativeApp.value = true;

        useRouter().replace({
          query: {
            ...useRoute().query,
            a: undefined,
          },
        });

        await nextTick();
      } else {
        token = useLocalStorage(`tokens.${useRoute().params.event}`, '').value;
      }

      const response = token
        ? await useApi.get('/public/app', {
          headers: {
            'x-app-token': token,
          },
        }).catch(() => {})
        : null;

      if (response) {
        this.isLoaded = true;
        this.event = response.event;
        this.guest = response.guest;
        this.items = response.items;
        this.responses = response.responses;
      }

      return response;
    },

    async appAccessed() {
      const response = await useApi.post('/public/app/accessed', {
        headers: {
          'x-app-token': this.token?.value,
        },
      }).catch(() => {
        useToast.error(useT('poly.loginError'));
      });

      if (response) {
        this.guest = response;
      }

      return response;
    },

    async helpdeskSendQuestion(question) {
      const response = await useApi.post('/public/app/items/helpdesk', {
        headers: {
          'x-app-token': this.token?.value,
        },
        body: {
          question,
        },
      }).catch(() => {
        useToast.error(useT('poly.questionSubmissionFailed'));
      });

      if (response) {
        useToast(useT('poly.questionSubmissionSuccess'));
      }

      return response;
    },

    async feedbackSend({ value, params }) {
      const response = await useApi.post('/public/app/responses/feedback', {
        headers: {
          'x-app-token': this.token?.value,
        },
        body: {
          value,
          params,
        },
      }).catch(() => {
        useToast.error(useT('poly.feedbackSubmissionFailed'));
      });

      return response;
    },

    async ratingSend({ value, params }) {
      const response = await useApi.post('/public/app/responses/rating', {
        headers: {
          'x-app-token': this.token?.value,
        },
        body: {
          value,
          params,
        },
      }).catch(() => {
        useToast.error(useT('poly.ratingSubmissionFailed'));
      });

      return response;
    },

    async addFavoriteSession(sessionId) {
      _set(this.guest, 'guestAppData.favoriteSessions', [...(this.guest?.guestAppData?.favoriteSessions || []), sessionId]);

      const response = await useApi.post('/public/app/guest/favorite-session', {
        headers: {
          'x-app-token': this.token?.value,
        },
        body: {
          sessionId,
        },
      }).catch(() => {
        useToast.error(useT('poly.addToFavoritesFailed'));
      });

      if (response) {
        this.guest = response;

        const session = this.getAgendaItems?.find((item) => item._id === sessionId);
        if (session) {
          const data = {
            type: 'like_agenda',
            value: {
              itemId: sessionId,
              workspace: useWorkspaceStore().domain,
              eventId: this.event?._id,
              date: session.startDateTime,
            },
          };

          console.log('[ReactNativeWebView] postMessage:', data);
          window?.ReactNativeWebView?.postMessage?.(JSON.stringify(data));
        }
      }

      return response;
    },

    async removeFavoriteSession(sessionId) {
      _set(this.guest, 'guestAppData.favoriteSessions', (this.guest?.guestAppData?.favoriteSessions || []).filter((id) => id !== sessionId));

      const response = await useApi.delete('/public/app/guest/favorite-session', {
        headers: {
          'x-app-token': this.token?.value,
        },
        body: {
          sessionId,
        },
      }).catch(() => {
        useToast.error(useT('poly.removeFromFavoritesFailed'));
      });

      if (response) {
        this.guest = response;

        const data = {
          type: 'unlike_agenda',
          value: {
            itemId: sessionId,
            workspace: useWorkspaceStore().domain,
            eventId: this.event?._id,
          },
        };

        console.log('[ReactNativeWebView] postMessage:', data);
        window?.ReactNativeWebView?.postMessage?.(JSON.stringify(data));
      }

      return response;
    },

    async getMessages(stage) {
      const response = await useApi.get('/public/app/messages', {
        params: {
          stage,
        },
        headers: {
          'x-app-token': this.token?.value,
        },
      }).catch(() => {
        useToast.error(useT('poly.chatLoadingFailed'));
      });

      return response;
    },

    async addMessage({ text, stage }) {
      const response = await useApi.post('/public/app/messages', {
        headers: {
          'x-app-token': this.token?.value,
        },
        body: {
          text,
          stage,
        },
      }).catch(() => {
        useToast.error(useT('poly.chatSendingFailed'));
      });

      return response;
    },

    async likeMessage(messageId) {
      const response = await useApi.post(`/public/app/messages/${messageId}/like`, {
        headers: {
          'x-app-token': this.token?.value,
        },
      }).catch(() => {
        useToast.error(useT('poly.errorOccurred'));
      });

      return response;
    },

    async unlikeMessage(messageId) {
      const response = await useApi.post(`/public/app/messages/${messageId}/unlike`, {
        headers: {
          'x-app-token': this.token?.value,
        },
      }).catch(() => {
        useToast.error(useT('poly.errorOccurred'));
      });

      return response;
    },

    async removeMessage(messageId) {
      const userToken = useCookie('token').value;
      const response = await useApi.delete(useCookie('token').value ? `/app/messages/${messageId}` : `/public/app/messages/${messageId}`, {
        headers: {
          ...(userToken ? { Authorization: userToken } : {}),
          'x-app-token': this.token?.value,
        },
      }).catch(() => {
        useToast.error(useT('poly.errorOccurred'));
      });

      return response;
    },

    goBack() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        navigateTo(`/app/${useRoute().params?.event}`);
      }
    },
  },

  getters: {
    token: () => useLocalStorage(`tokens.${useRoute().params.event}`, ''),

    lang: (state) => state.guest?.properties?.lang || state.event?.general?.langs?.[0] || 'en',
    getEventDays(state) {
      const startDate = state.event?.general?.startDate;
      const endDate = state.event?.general?.endDate;
      const items = [];

      if (endDate) {
        const daysBetween = useDayjs(endDate).tz(state.event?.general?.timezone).startOf('day').diff(useDayjs(startDate).tz(state.event?.general?.timezone).startOf('day'), 'day');
        for (let i = 0; i <= daysBetween; i++) {
          items.push(useDayjs(startDate).add(i, 'day').format());
        }
      } else if (startDate) {
        items.push(startDate);
      }

      return items;
    },

    getAgendaItems(state) {
      const eventDays = useGuestAppStore().getEventDays;

      return (state.items?.agenda || []).map((item) => ({
        ...item,
        startDateTime: getAgendaItemDate({
          date: eventDays[item.dayIndex],
          time: item.startTime,
          timezone: state.event?.general?.timezone,
        }),
        endDateTime: getAgendaItemDate({
          date: eventDays[item.dayIndex],
          time: item.endTime,
          timezone: state.event?.general?.timezone,
        }),
      }));
    },

    isNewsEnabled: (state) => state.items?.news?.length,
    isHelpdeskEnabled: (state) => state.event?.guestApp?.helpdesk?.isActive,
    isFeedbackEnabled: (state) => state.event.guestApp?.feedbackSurvey?.isPublished && state.event.guestApp?.feedbackSurvey?.questions?.length && !state.responses?.feedback?.length,
    isSpeakersEnabled: (state) => state.items?.speaker?.length,
    isSponsorsEnabled: (state) => state.items?.sponsor?.length,
  },
});
